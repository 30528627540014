import React, { useState } from 'react';
import { FaLinkedin, FaGithub, FaInstagram, FaFacebook } from 'react-icons/fa';

const Contact = ({ isDarkMode }) => {
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmissionMessage('');
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    try {
      const response = await fetch('/.netlify/functions/emailSender', {
        method: 'POST',
        body: JSON.stringify(formProps),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmissionMessage('Thank you for your message! We will get back to you soon.');
        event.target.reset();
      } else {
        setSubmissionMessage('Sorry, there was an error with your submission. Please try again.');
      }
    } catch (error) {
      setSubmissionMessage('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <section className="py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center">
          {/* Conditional Image Rendering */}
          <img
            src={isDarkMode ? 'https://i.imgur.com/HcGKEQE.jpeg' : 'https://i.imgur.com/uwOl8QX.jpeg'} //dark mode url and light mode url go here
            alt={isDarkMode ? 'Dark Mode Image' : 'Light Mode Image'}
            className="mb-4"
            style={{ width: '500px' }} // Adjust the width as needed
          />

          {/* Form */}
          <div className="w-full flex justify-center">
            <form name="contact" onSubmit={handleSubmit} className="w-full max-w-md">
              <input type="hidden" name="form-name" value="contact" />
              <div className="mb-4">
                <input type="text" name="name" placeholder="Your Name" className="p-2 border rounded text-gray-700 w-full" required />
              </div>
              <div className="mb-4">
                <input type="email" name="email" placeholder="Your Email" className="p-2 border rounded text-gray-700 w-full" required />
              </div>
              <div className="mb-4">
                <textarea name="message" placeholder="Your Message" className="p-2 border rounded text-gray-700 w-full" required></textarea>
              </div>
              <div className="flex justify-center">
                <button type="submit" className="bg-gray-500 hover:bg-gray-700 text-black font-bold py-2 px-4 rounded">
                  Send
                </button>
              </div>
            </form>
          </div>

          {submissionMessage && <p className="text-center mt-4">{submissionMessage}</p>}
        </div>

        <div className="flex items-center justify-center mt-8">
          {/* Your social icons */}
          <a href="https://www.linkedin.com/in/vc-codez/" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-red-600 mr-4">
            <FaLinkedin className="text-2xl" />
          </a>
          <a href="https://github.com/vanessa-co" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-yellow-600 mr-4">
            <FaGithub className="text-2xl" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-green-600 mr-4">
            <FaInstagram className="text-2xl" />
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-indigo-600">
            <FaFacebook className="text-2xl" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;




