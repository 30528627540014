import React from 'react';

const Portfolio = ({ isDarkMode }) => {
  const textClassName = isDarkMode ? 'text-white' : 'text-black'; 

  return (
    <section className="mb-8">
      <h2 className={`text-2xl font-light ${textClassName} mb-4`} style={{ fontFamily: "'Nunito', sans-serif", fontSize: '18px' }}> {/* Adjust fontSize here */}
        Coming Soon! Under Construction...🚧🛠️
      </h2>
      <div className="gif-container">
        {/* Use the direct GIF link in the src attribute */}
        <img src="https://media.giphy.com/media/o0vwzuFwCGAFO/giphy.gif" alt="Building in Progress" />
      </div>
    </section>
  );
};

export default Portfolio;
