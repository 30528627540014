import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = ({ isDarkMode }) => {
  const navBarStyle = {
    backgroundColor: isDarkMode ? 'black' : 'white',
    // fontFamily: "'Nunito', sans-serif",
    // fontFamily: "'Quicksand', sans-serif",
    fontFamily: "'Comfortaa', cursive",
    fontSize: '18px', // You can set the font size as desired
    fontWeight: 400, // Use the appropriate weight; numbers like 300 for light, 400 for regular, etc.
    color: isDarkMode ? 'white' : 'black', // reverse to show line under nav bar
    // borderBottom: `2px solid ${isDarkMode ? 'lightgray' : 'gray'}`,
    transition: 'border-bottom 0.3s ease',
  };

  return (
    <nav style={navBarStyle}>
      <ul className="flex justify-center">
        <li className="mx-4">
          <NavLink 
            to="/" 
            className={({ isActive }) => isActive ? "text-grat-600 hover:text-gray-600 cursor-pointer" : "hover:text-gray-600 cursor-pointer"}
          >
            Home
          </NavLink>
        </li>
        <li className="mx-4">
          <NavLink 
            to="/portfolio" 
            className={({ isActive }) => isActive ? "text-gray-600 hover:text-gray-600 cursor-pointer" : "hover:text-gray-600 cursor-pointer"}
          >
            Portfolio
          </NavLink>
        </li>
        <li className="mx-4">
          <NavLink 
            to="/contact" 
            className={({ isActive }) => isActive ? "text-gray-600 hover:text-gray-600 cursor-pointer" : "hover:text-gray-600 cursor-pointer"}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
