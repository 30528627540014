import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './Home';
import Portfolio from './Portfolio';
import Contact from './Contact';
import './tailwind.css';
import './style.css';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === null ? true : savedMode === 'true';
  });

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  return (
    <Router>
      <div className={darkMode ? 'bg-black min-h-screen text-gray-100' : 'bg-white min-h-screen text-black'}>
        <header className={darkMode ? 'bg-black relative' : 'bg-white relative'}>
          <div className="container mx-auto px-4 flex justify-between items-center py-4">
            <div className="logo-container">
              <img
                src={darkMode ? 'https://i.imgur.com/jTgEpVA.jpg' : 'https://i.imgur.com/G7XRZsX.jpg'}
                alt={darkMode ? 'Dark Mode Logo' : 'Light Mode Logo'}
                className="h-14"
              />
            </div>
          </div>
          <div className="toggle-container absolute top-4 right-4">
            <label className="switch">
              <input type="checkbox" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="mt-4">
            <NavBar isDarkMode={darkMode} />
          </div>
        </header>

        <main className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<Home isDarkMode={darkMode} />} exact />
            <Route path="/portfolio" element={<Portfolio isDarkMode={darkMode} />} />
            <Route path="/contact" element={<Contact isDarkMode={darkMode} />} />
          </Routes>
        </main>

        <footer className={darkMode ? 'bg-black py-4' : 'bg-white py-4'}>
          <div className="container mx-auto px-4 text-center">
            <p className={darkMode ? 'text-white text-sm' : 'text-black text-sm'}>&copy; 2024 VC codez. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
