import React, { useState } from 'react';
import { FaLinkedin, FaGithub, FaInstagram, FaFacebook, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import './style.css';

const Home = ({ isDarkMode }) => {
  const [showFullText, setShowFullText] = useState(true);
  const staticText = "Hi! I'm Vanessa"; // "Hi! I'm Vanessa" text, styled in CSS

  const additionalText = (
    <>
      I'm a full stack software engineer, <br/> and artist. I love what I do. <br/> Let's build something!
    </>
  );

  return (
    <section className="bg--200 py-0 flex items-center justify-center">
      <div className="container mx-auto px-4 flex flex-col items-center justify-center space-y-8">

        {/* Photo */}
        <div className="w-72 h-72 rounded-full bg-transparent-300 overflow-hidden">
          <img 
            src={isDarkMode ? 'https://i.imgur.com/9F9lgTK.jpeg' : 'https://i.imgur.com/YhxGcg4.jpeg'} 
            alt="Profile" 
            className="w-full h-full object-cover"
          />
        </div>

        {/* Biography Speech Bubble */}
        <div className="w-full md:w-1/5 flex justify-center">
          <div className="speech-bubble">
            <p className="animated-text">{staticText}</p>
            {showFullText && (
              // Inline style for dynamic text color based on dark mode
              <p className="bio-text" style={{ color: isDarkMode ? 'gray' : 'gray' }}>
                {additionalText}
              </p>
            )}
            <div className="toggle-minimize" onClick={() => setShowFullText(!showFullText)}>
              {showFullText ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
        </div>

        {/* Social Icons */}
        <div className="social-icons flex flex-row justify-center space-x-4">
          <a href="https://www.linkedin.com/in/vc-codez/" className="text-gray-600 hover:text-red-500 transition duration-500 ease-in-out">
            <FaLinkedin size={30} />
          </a>
          <a href="https://github.com/vanessa-co" className="text-gray-600 hover:text-yellow-500 transition duration-500 ease-in-out">
            <FaGithub size={30} />
          </a>
          <a href="https://www.instagram.com" className="text-gray-600 hover:text-green-500 transition duration-500 ease-in-out">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.facebook.com" className="text-gray-600 hover:text-indigo-500 transition duration-500 ease-in-out">
            <FaFacebook size={30} />
          </a>
        </div>

      </div>
    </section>
  );
};

export default Home;
